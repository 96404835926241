export const zIndex = {
  negative: -1,
  neutral: 0,
  layer: 1,
  elevated: 2,
  overlay: 7,
  globe: 8,
  headingImage: 9,
  navigation: 10,
  header: 11,
  high: 9997,
  modal: 9998,
  loader: 9999,
} as const;
